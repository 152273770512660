import { IonButton, IonButtons, IonIcon, isPlatform } from "@ionic/react";
import { App } from "@capacitor/app";
import packageJson from "../../package.json";
import {
  DataGame,
  RankingOnlineRequest,
} from "../_redux/models/game.model";
import { AuthState, MyUser } from "../_redux/models/auth.model";
import { PartidaState } from "../_redux/models/partida.model";
import { Base64 } from "js-base64";
import { Share } from "@capacitor/share";
import {
  BannerAdOptions,
  BannerAdPosition,
  BannerAdSize,
} from "@capacitor-community/admob";
import {
  alertCircleSharp,
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  homeSharp,
  images,
  map,
  settings,
  shareSocialSharp,
  storefrontSharp,
} from "ionicons/icons";
import { history } from "../_helpers/history";
import { configData } from "../data/configData";
import { LogrosUsuario } from "../_redux/models/logro.models";
import Enumerable from "linq";
//import { RateApp } from "capacitor-rate-app";
import { starHalf } from 'ionicons/icons';

export const isToday = (dateToCheck) => {
  const today = new Date();
  const date = new Date(dateToCheck);
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const currentTime = () => {
  const today = new Date();
  return dateTimeFormat(today);
};

export const dateTimeFormat = (date) => {
  const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  return `${hours}:${minutes}`;
};

export const diffMsDates = (dateIni, dateEnd, minutes) => {
  dateIni.setMinutes(dateIni.getMinutes() - minutes);
  const diffMs = dateIni - dateEnd;
  return diffMs;
};

export const isEmpty = (obj: any): boolean => {
  return obj == null || Object.keys(obj).length === 0;
};

export const getVersion = async () => {
  if (isPlatform("capacitor")) {
    const info = await App.getInfo();
    try {
      return ` ${info.name}-${info.id}-${info.build}-${info.version}`;
    } catch (error) {
      console.log("ERROR", error);
    }
  } else {
    return ` ${packageJson.name} - ${packageJson.version}`;
  }
};

export const groupBy = (array: any[], property: string) => {
  return array.reduce((r, a) => {
    r[a[property]] = r[a[property]] || [];
    r[a[property]].push(a);
    return r;
  }, Object.create(null));
};

//Vamos a guardar o ranking
export const OperacionSaveR = (
  dataRankingSave: RankingOnlineRequest,
  partida: PartidaState,
  AuthUser: AuthState
) => {
  dataRankingSave.Correcta = partida.aciertos;
  dataRankingSave.Incorrecta = partida.errores;
  dataRankingSave.Nivel = partida.level;
  dataRankingSave.UserId = AuthUser.user.Id;
  dataRankingSave.Finalizado = partida.finalizarJuego;
  if (partida.finalizarJuego == null) {
    partida.finalizarJuego = false;
  } else {
    partida.finalizarJuego = partida.finalizarJuego;
  }
  if (partida?.id != null) {
    dataRankingSave.Id = partida.id;
  } else {
    dataRankingSave.Id = -1;
  }
  dataRankingSave.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
  dataRankingSave.Aux = Ordenar(dataRankingSave, partida);
  dataRankingSave.MiAppId = 100;
  return dataRankingSave;
};

//
export const Ordenar = (
  dataRankingSave: RankingOnlineRequest,
  partida: PartidaState
) => {
  let meuDatos: string = Date.now().toString() + "|";
  meuDatos += dataRankingSave.UserId + "|";
  meuDatos += dataRankingSave.Correcta + "|";
  meuDatos += dataRankingSave.Incorrecta + "|";
  meuDatos += dataRankingSave.MiAppId + "|";
  meuDatos += dataRankingSave.Nivel + "|";
  meuDatos += dataRankingSave.Punto + 453 + "|";
  meuDatos += dataRankingSave.TipoRanking + "|";
  meuDatos += dataRankingSave.Finalizado + "|";
  meuDatos += partida.tiempoControl + "|";
  meuDatos += partida.currentQuestion + "|";
  meuDatos += dataRankingSave.Id + "|";
  meuDatos += "0"; //numero máximo de puntuación
  return filtrarMeuDatos(meuDatos);
};

export const OrdenarLoja = (loja: MyUser) => {
  let meuDatos: string = Date.now().toString() + "|";
  meuDatos += loja?.DatosExtendido?.Ayuda50 + "|";
  meuDatos += loja?.DatosExtendido?.AyudaContestar2Veces + "|";
  meuDatos += loja?.DatosExtendido?.AyudaMasTiempo + "|";
  meuDatos += loja?.DatosExtendido?.AyudaPreguntaCorrecta + "|";
  meuDatos += loja?.DatosExtendido?.PuntosGlobales + "|";
  meuDatos += loja?.aspj + "|";
  meuDatos += loja?.DatosExtendido.Id + "|";
  return filtrarMeuDatos(meuDatos);
};

export const filtrarMeuDatos = (data: string) => {
  return Buffer.from(data).toString("base64");
};

export const decodeBase64 = (data: string) => {
  return Buffer.from(data, "binary").toString("base64");
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const GuardarPartida = (partida: PartidaState) => {

  localStorage.setItem("jogo", filtrarMeuDatos(JSON.stringify(partida)));
};

export const RecuperarPartida = () => {
  let midecode = localStorage.getItem("jogo");
  if (midecode === null) {
    return null;
  }

  midecode = Base64.decode(midecode);

  return JSON.parse(midecode);
};
export const YaPaso1HoraDesdeQueGano5VidasGratis = () => {
  try {
    const lastSavedDateString = localStorage.getItem("lastSavedDate");
    if (lastSavedDateString) {
      const lastSavedDate = new Date(lastSavedDateString);
      const oneHourInMilliseconds = 60 * 60 * 1000;
      const currentTime = new Date();
      const timeDiff = currentTime.getTime() - lastSavedDate.getTime();
      if (timeDiff >= oneHourInMilliseconds) {
        console.log(
          "Ha pasado una hora o más desde la última vez que se guardó la fecha en LocalStorage."
        );
        return true;
      } else {
        console.log(
          "No ha pasado una hora desde la última vez que se guardó la fecha en LocalStorage."
        );
        return false;
      }
    } else {
      console.log("No se ha guardado ninguna fecha en LocalStorage.");
      return true;
    }
  } catch (error) {
    return true;
  }
};

export const validarCorreoElectronico = (correo: string) =>  {
  const patronCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return patronCorreo.test(correo);
}

export const VerificarSiTocaLogro = (
  data: DataGame,
  partida: PartidaState,
  logros: LogrosUsuario[],
  tiempo: number,
  posicionActualDelUsuario: number,
  usuario: AuthState
) => {
  /*
  console.log("data", data);
  console.log("partida", partida);
  console.log("logros", logros);
  console.log("tiempo", tiempo);
  console.log("usuario", usuario);
*/

  let logroGanado: LogrosUsuario = null;

  if (!usuario.isAuth) {
    return logroGanado;
  }

  if (tiempo > 97) {
    //El más rapido
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 2 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario === 1) {
    //logro por ganar la mejor posición - 1º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 8 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario === 2) {
    //logro posicion- 2º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 26 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario === 3) {
    //logro posicion- 3º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 74 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario <= 5 && posicionActualDelUsuario !==0) {
    //logro posicion - 5º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 25 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario <= 10 && posicionActualDelUsuario !==0) {
    //logro posición - 10º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 7 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario <= 50 && posicionActualDelUsuario !==0) {
    //logro posición - 50º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 4 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (posicionActualDelUsuario <= 100 && posicionActualDelUsuario !==0) {
    //logro posición - 100º
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 30 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.aciertos >= 10) {
    //logro por acertar 10
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 11 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.aciertos >= 50) {
    //logro por acertar 50
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 12 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.level >= 3) {
    //nivel 3
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 13 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.level >= 5) {
    //nivel 5
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 14 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.level >= 10) {
    //nivel 10
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 15 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.point >= 1000) {
    //Usuario con más de 1000 puntos
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 16 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.point >= 5000) {
    //Usuario con más de 5000 puntos
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 17 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.point >= 10000) {
    //Usuario con más de 10000 puntos
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 18 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.point >= 50000) {
    //Usuario con más de 50000 puntos
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 19 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.point >= 100000) {
    //Usuario con más de 100.000 puntos
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 20 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (partida.aciertos >= 100) {
    //logro por acertar 100
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 21 && p.TieneLogro === false)
      .firstOrDefault();
  }
  // 22 Por compartir la app
  // 23 Por explorar la opción ver detalle de la ciudad
  // 24 Por valorar la App
  // 73 Por explorar la opción punto turistico
  // 95 Por explorar la opción otras Apps
  // 27 Por la primera compra
  // 28 Por contestar todas las preguntas
  // 29 Por visitar nuestra redes sociales
  // 97
  else if (usuario.user.DatosExtendido.NumeroPartida >= 5) {
    //jugador con más de 5 partida
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 31 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.NumeroPartida >= 10) {
    //jugador con más de 10 partida
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 32 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.NumeroPartida >= 50) {
    //jugador con más de 50 partida
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 55 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.NumeroPartida >= 100) {
    //jugador con más de 100 partida
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 34 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.PuntosGlobales >= 10000) {
    //jugador con más de 10mil puntos global
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 35 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.PuntosGlobales >= 50000) {
    //jugador con más de 50mil puntos global
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 36 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.PuntosGlobales >= 100000) {
    //jugador con más de 100mil puntos global
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 37 && p.TieneLogro === false)
      .firstOrDefault();
  } else if (usuario.user.DatosExtendido.PuntosGlobales >= 100000) {
    //jugador con más de 1milhao puntos global
    logroGanado = Enumerable.from(logros)
      .where((p) => p.LogroId === 38 && p.TieneLogro === false)
      .firstOrDefault();
  }

  // TODO - No mostrar las opciones menores en el listado, no tiene sentido mostrar que hay un logro de 1 millon si el usuario no gano el de 10mil puntos por ejemplo

  //console.log("logro ganado", logroGanado);

  return logroGanado;
};

export const CambioDeNivel = (partida: PartidaState) => {
  //verifica App de la biblia para ver como hacer los logros.
};

export const RandomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const shareHander = async (t) => {
  let titleApp = t("name_app");
  titleApp += " : " + t("share_title");
  await Share.share({
    title: titleApp,
    text: t("description_app"),
    url: configData.web,
    dialogTitle: t("share_title"),
  });
};
export function MenuApp(present, t) {
  return (
    <IonButtons slot="primary">
      <IonButton
        color="secondary"
        onClick={() =>
          present({
            buttons: [
              {
                text: t("tourist_points"),
                icon: images,
                handler: () => history.push("photo"),
              },
              {
                text: t("cities"),
                icon: map,
                handler: () => history.push("cities"),
              },
              {
                text: t("home"),
                icon: homeSharp,
                handler: () => history.push("dashboard"),
              },

              {
                text: t("other_apps"),
                icon: apps,
                handler: () => history.push("apps"),
              },
              {
                text: t("tutorial"),
                icon: alertCircleSharp,
                handler: () => history.push("ayuda"),
              },
              {
                text: t("share"),
                icon: shareSocialSharp,
                handler: () => shareHander(t),
              },
              {
                text: t("store"),
                icon: storefrontSharp,
                handler: () => history.push("store"),
              },
              {
                text: t("settings"),
                icon: settings,
                handler: () => history.push("profile"),
              },
              
              {
                text: t("rate_app"),
                icon: starHalf,
                //handler: () => RateApp.requestReview(),
              }
            ],
            header: t("app_options"),
          })
        }
      >
        <IonIcon
          slot="icon-only"
          ios={ellipsisHorizontal}
          md={ellipsisVertical}
        />
      </IonButton>
    </IonButtons>
  );
}

export function deshabilitarBoton(
  key: number,
  respuesta1: boolean,
  respuesta2: boolean,
  respuesta3: boolean,
  respuesta4: boolean
): boolean {
  switch (key) {
    case 0:
      return respuesta1;
    case 1:
      return respuesta2;
    case 2:
      return respuesta3;
    case 3:
      return respuesta4;
    default:
      return false;
  }
}

export const optionsBanner: BannerAdOptions = {
  adId: isPlatform("android")
    ? "ca-app-pub-8932590852481744/3419002381"
    : "ca-app-pub-8932590852481744/3634624807",
  adSize: BannerAdSize.BANNER,
  position: BannerAdPosition.BOTTOM_CENTER,
  npa: true,
};

export function addSeconds(date, seconds) {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
}
